@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Montserrat", sans-serif;
}

.MuiPagination-ul{
  display: flex;
  justify-content: space-between;
}